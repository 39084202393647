<template>
  <div class="accountManagement">
   
  
      <el-tabs v-model="queryInfo.type" @tab-click="search">
        <el-tab-pane :label="`报名成功(${numinfo.singUpSuccess})`" name="2">
          <div class="search">
            <div class="search_flex">
              <el-button type="primary"  @click="exportExcel">导出名单</el-button>
             <div style="margin-left: 30px;color: #7C7F8E;">签到状态：</div>
              <el-radio-group v-model="queryInfo.registerStatus" @change="search">
                <el-radio-button
                  label="">全部</el-radio-button>
                  <el-radio-button
                  :label="1">已签到</el-radio-button>
                  <el-radio-button
                  :label="0">未签到</el-radio-button>
              </el-radio-group>
            </div>
            <div class="search_right">
              <el-input
                placeholder="请输入姓名、企业名称"
                v-model="queryInfo.queryConditions"
                class="input-with-select"
                clearable
              >
                <el-button slot="append" @click="search">搜索</el-button>
              </el-input>
            </div>
          </div>
        
          <el-table
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中..."
            class="table_data"
            :data="tableData"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
            ref="multipleTable"
            style="width: 100%"
            tooltip-effect="dark"
            :row-key="getRowKey"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" align="center" :reserve-selection="true" />
            <el-table-column prop="userId" label="编号" align="center" >
            </el-table-column>
            <el-table-column label="微信头像" align="center" >
              <template v-slot="{ row }">
                <img v-if="row.headImgUrl" width="30" height="30" :src="row.headImgUrl" alt="">
                <img v-else
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/202302061802.png"
              class="img"
              alt=""
            />
              </template>
            </el-table-column>
            <!-- <el-table-column prop="nickName" label="微信昵称" align="center" >
            </el-table-column> -->
            <el-table-column prop="realName" label="姓名" align="center" >
            </el-table-column>

            <el-table-column label="性别" align="center" >
              <template v-slot="{ row }">
                <div class="sex" v-if="row.sex == '1'">男</div>
                <div class="sex" v-else>女</div>
              </template>
            </el-table-column>

            <el-table-column prop="position" label="职位" align="center"> </el-table-column>
            <el-table-column prop="phone" label="手机号" width="120" align="center">
            </el-table-column>
            <el-table-column label="所在城市" align="center" >
              <template v-slot="{ row }">
                <span>{{ row.city }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="companyFullName" label="企业全称" align="center">
              <template v-slot="{ row }">
                <span>{{ row.companyFullName }}</span>
              </template>
            </el-table-column>
           
            <el-table-column
              prop="createTime"
              label="报名时间"
              sortable
              width="100"
              align="center"
            ></el-table-column>
            <el-table-column v-if="activityChannel==2" prop="companyFullName" label="操作" align="center">
              <template v-slot="{ row }">
                <span
                  style="color: #4e93fb; cursor: pointer"
                  v-if="row.registerStatus == 0 || row.registerStatus == null"
                  @click="userSignIn(row)"
                  >签到</span
                >
                <span style="color: #ff7d18; cursor: pointer" v-if="row.registerStatus == 1"
                  >已签到</span
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane :label="`待审核(${numinfo.beReviewed})`" name="1">
          <div class="search">
            <div>
              <!-- <el-button type="primary" plain @click="handleAdd">导出名单</el-button> -->
            </div>
            <div class="search_right">
              <el-input
                placeholder="请输入姓名、企业名称"
                v-model="queryInfo.queryConditions"
                class="input-with-select"
                clearable
              >
                <el-button slot="append" @click="search">搜索</el-button>
              </el-input>
            </div>
          </div>
          <el-table
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            class="table_data"
            :data="tableData"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
            ref="multipleTable"
            style="width: 100%"
            tooltip-effect="dark"
            :row-key="getRowKey"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" align="center" :reserve-selection="true" />
            <el-table-column prop="userId" label="编号" align="center"> </el-table-column>
            <el-table-column label="微信头像" align="center" >
              <template v-slot="{ row }">
                <img v-if="row.headImgUrl" width="30" height="30" :src="row.headImgUrl" alt="">
                <img v-else
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/202302061802.png"
              class="img"
              alt=""
            />
              </template>
            </el-table-column>
            <!-- <el-table-column prop="nickName" label="微信昵称" align="center" >
            </el-table-column> -->
            <el-table-column prop="realName" label="姓名" align="center"> </el-table-column>

            <el-table-column label="性别" align="center">
              <template v-slot="{ row }">
                <div class="sex" v-if="row.sex == '1'">男</div>
                <div class="sex" v-else>女</div>
              </template>
            </el-table-column>

            <el-table-column prop="position" label="职位" align="center"> </el-table-column>
            <el-table-column prop="phone" label="手机号" align="center"> </el-table-column>
            <el-table-column prop="city" label="所在城市" align="center"> </el-table-column>
            <el-table-column prop="companyFullName" label="企业全称" align="center">
              <template v-slot="{ row }">
                <span>{{ row.companyFullName }}</span>
              </template>
            </el-table-column>
           <el-table-column
              prop="createTime"
              label="报名时间"
              sortable
              width="100"
              align="center"
            ></el-table-column>
            
            <el-table-column  v-if="activityChannel==2" label="操作" align="center">
              <template v-slot="{ row }">
                <div class="btn">
                  <span class="pass" @click="passClick(row)">通过</span>
                  <span class="un_pass" @click="unPassClick(row)">不通过</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane :label="`已审核(${numinfo.auditSuccess})`" name="3">
          <div class="search">
            <div class="search_flex">
              <!-- <el-button type="primary" plain @click="handleAdd">导出名单</el-button> -->
              <div style="color: #7C7F8E;">审核状态：</div>
              <el-radio-group v-model="queryInfo.applyStatus" @change="search">
                <el-radio-button
                  :label="item.id"
                  v-for="(item, index) in InformationTypes"
                  :key="index"
                  >{{ item.value }}</el-radio-button
                >
              </el-radio-group>
            </div>
            <div class="search_right">
              <el-input
                placeholder="请输入姓名、企业名称"
                v-model="queryInfo.queryConditions"
                class="input-with-select"
                clearable
              >
                <el-button slot="append" @click="search">搜索</el-button>
              </el-input>
            </div>
          </div>
          <el-table
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            class="table_data"
            :data="tableData"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
            ref="multipleTable"
            style="width: 100%"
            tooltip-effect="dark"
            :row-key="getRowKey"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" align="center" :reserve-selection="true" />
            <el-table-column prop="userId" label="编号" align="center" >
            </el-table-column>
            <el-table-column label="微信头像" align="center" >
              <template v-slot="{ row }">
                <img v-if="row.headImgUrl" width="30" height="30" :src="row.headImgUrl" alt="">
                <img v-else
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/202302061802.png"
              class="img"
              alt=""
            />
              </template>
            </el-table-column>
            <!-- <el-table-column prop="nickName" label="微信昵称" align="center" >
            </el-table-column> -->
            <el-table-column prop="realName" label="姓名" align="center" >
            </el-table-column>

            <el-table-column label="性别" align="center" >
              <template v-slot="{ row }">
                <div class="sex" v-if="row.sex == '1'">男</div>
                <div class="sex" v-else>女</div>
              </template>
            </el-table-column>

            <el-table-column prop="position" label="职位" align="center"> </el-table-column>
            <el-table-column prop="phone" label="手机号"  align="center">
            </el-table-column>
            <el-table-column prop="city" label="所在城市"  align="center">
            </el-table-column>
            <el-table-column prop="companyFullName" label="企业全称" align="center">
              <template v-slot="{ row }">
                <span>{{ row.companyFullName }}</span>
              </template>
            </el-table-column>
           
            <el-table-column prop="position" label="审核状态" align="center">
              <template v-slot="{ row }">
                <span v-if="row.applyStatus == 1">待审核</span>
                <span v-if="row.applyStatus == 2">审核通过</span>
                <span v-if="row.applyStatus == 3">拒绝</span>
              </template>
            </el-table-column>

            <el-table-column prop="updateName" label="操作人" align="center"></el-table-column>
            <el-table-column
              prop="updateTime"
              label="操作时间"
              sortable
            width="180"
              align="center"
            ></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
        <!-- 拒绝的理由弹窗 -->
    <el-dialog title="不通过" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div class="refuse">
        <el-form
          :model="refuseObj"
          :rules="rules"
          ref="ruleForm"
          label-width="60px"
          class="demo-ruleForm"
        >
          <el-form-item label="理由" prop="refuseReason">
            <el-input
              type="textarea"
              :rows="10"
              placeholder="请输入不通过理由"
              v-model="refuseObj.refuseReason"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  listSingUpPage,
  exportExcel,
  listAll,
  userSignIn,
  refuseUserRegisterActivity,
  auditUserRegisterActivity
} from '@/api/activity'
const defaultQueryInfo = Object.freeze({
  activityId: null,
  orderByParam: 'DESC',
  pageNum: 1,
  pageSize: 10,
  registerStatus:'',//签到状态;0是未签到，1是已签到")
  userLevel:'',//("用户等级;0是普通用户，1是个人会员，2是企业会员")
  type: '2',
  applyStatus: 0//申请状态;全部是传0；2是通过，3是拒绝
})

const defaultRefuseObj = Object.freeze({
  id: '', //当前用户的id
  refuseReason: '' //拒绝的理由
})

export default {
  name: 'accountManagement',
 
  data() {
    return {
      activityId: this.$route.query.id,
      activityChannel:this.$route.query.activityChannel,
      ids:[],
      total:0,
      // 遮罩层
      loading: true,
      tableData: [], //表格数据
      queryInfo: { ...defaultQueryInfo },
      numinfo:{
        auditSuccess:0,//审核成功
        beReviewed:0,//待审核
        singUpSuccess:0
       },//报名成功数
       rules: {
        refuseReason: [{ required: true, message: '请输入不通过理由', trigger: 'blur' }]
      }, //提交理由的验证表单
      dialogVisible: false, //拒绝理由的弹窗
      refuseObj: {
        id: null,
        refuseReason: null
      },
      topDetails: {}, //头部活动信息
      InformationTypes: [
        {
          value: '全部',
          id: '0'
        },
        {
          value: '已通过',
          id: '2'
        },
        {
          value: '不通过',
          id: '3'
        }
      ],
     
    
    }
  },

  created() {
    //页面加载完成  调取数据
    this.search()
   
  },
  methods: {
    //统计报名各个状态总数

  async  listAll(){
    const {data:res}=await listAll (this.queryInfo)
    if(res.resultCode==200){
      this.numinfo=res.data
    }
    }, 
     //多选
     handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
    
    },
     //关闭拒绝理由的弹窗
     handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          Object.assign(this.refuseObj, defaultRefuseObj)
          done()
        })
        .catch((_) => {})
    },
     //签到
     userSignIn(row) {
      let query = {
        id: row.id
      }

      this.$alert('您是否确认签到？')
        .then(function () {
          return userSignIn(query)
        })
        .then(() => {
          this.search()
          this.$message.success('签到成功')
        })
        .catch(() => {})
    },
     //不通过审核
     unPassClick(row) {
      this.refuseObj.id = row.id
      this.dialogVisible = true
    },
     //提交按钮拒绝理由
     submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const { data: res } = await refuseUserRegisterActivity(this.refuseObj)
          if (res.resultCode === 200) {
            this.$message.success('提交成功')
            this.dialogVisible = false
            this.search()
           
          } else {
            this.$message.error('拒绝失败')
          }
        } else {
          this.$message.error('请输入拒绝理由')
        }
      })
    },
    //通过审核
    passClick(row) {
      let query = {
        id: row.id
      }

      this.$alert('您是否确认通过？')
        .then(function () {
          return auditUserRegisterActivity(query)
        })
        .then(() => {
          this.search()
          
          this.$message.success('通过成功')
        })
        .catch(() => {})
    },
  
        //批量导出
   async exportExcel(){
        this.loading=true
      const query={
        activityId:this.activityId,
        ids:this.ids,
        applyStatus:this.queryInfo.applyStatus,
        queryConditions:this.queryInfo.queryConditions,
        registerStatus:this.queryInfo.registerStatus,
        type:this.queryInfo.type
      }
        const res = await exportExcel(query) 
        if (res.data) {
          this.loading=false
          let blob = new Blob([res.data])
          let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          console.log(href);
          a.href = href // 指定下载'链接
          let nowdata = new Date();
        let year = nowdata.getFullYear();
        let month = nowdata.getMonth() + 1;
        let date = nowdata.getDate();
        let nowtime = year + "-";
        if (month < 10) {
            nowtime += "0";
        }
        nowtime += month + "-";
        if (date < 10) {
            nowtime += "0";
        }
        nowtime += date;
          a.download = `活动报名名单${nowtime}.xlsx` //指定下载文件名
          // a.download = "test.png";
          a.click() //触发下载
          window.URL.revokeObjectURL(a.href) //释放URL对象
          if (document.body.contains(a)) {
            document.body.removeChild(a) //释放标签
          }
          this.search()
          this.isIndeterminate = false;
          this.checkAll=false
        } else {
          this.$message.error('下载失败!')
        
      }
    },
      // 分页
      handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
   
    //保存首页勾选数据
    getRowKey(row) {
      return row.id
    },
    //获取表格数据
    async search() {
      this.queryInfo.activityId = this.activityId,
      this.loading = true
      const { data: res } = await listSingUpPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading = false
        this.listAll()
      } else {
        this.$message.error(res.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 0;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: none !important;
}
::v-deep .el-autocomplete{
  position: relative; 
    display:block;
}

.accountManagement {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 20px;
  /deep/ .el-tabs__item {
    font-size: 20px;
    color: #999999;
  }

  /deep/ .el-tabs__item.is-active {
    color: #4e93fb !important;
  }

  .table_data {
    .success {
      color: #ff7d18;
      font-size: 14px;
      font-weight: 400;
    }

    .error {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;

      p {
        color: #f53939;
        font-size: 14px;
        font-weight: 400;
      }

      .span {
        color: #999999;
        font-size: 12px;
        display: inline-block;
        width: 100%;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .new_span {
        color: #999999;
        line-height: 1.5em;
        font-size: 12px;
        cursor: pointer;
        font-weight: 400;
      }
    }

    .img {
      width: 42px;
      height: 42px;
      display: block;
      margin: 0 auto;
      border-radius: 4px;
    }

    .type_can {
      display: flex;
      flex-direction: row;
      align-items: center;

      em {
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 6px;
        background: #ff7d18;
        border-radius: 50%;
      }

      span {
        color: #ff7d18;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .type_xuan {
      display: flex;
      flex-direction: row;
      align-items: center;

      em {
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 6px;
        background: #001aff;
        border-radius: 50%;
      }

      span {
        color: #001aff;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .formal {
      width: 72px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(78, 147, 251, 0.1);
      color: #4e93fb;
      border-radius: 2px;
    }

    .tourist {
      width: 72px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 125, 24, 0.1);
      color: #ff7d18;
      border-radius: 2px;
    }

    .img_list {
      .new_img {
        width: 70px;
        height: 40px;
        border-radius: 4px;
        margin-right: 10px;
      }
    }

    .btn {
      width: 100%;
      display: flex;
      align-items: center;

      .pass {
        cursor: pointer;
        border: 1px solid #4e93fb;
        color: #4e93fb;
        height: 24px;
        box-sizing: border-box;
        display: block;
        border-radius: 4px;
        width: 50px;
        line-height: 24px;
        margin-right: 10px;
        text-align: center;
      }

      .un_pass {
        border: 1px solid #fb6956;
        color: #fb6956;
        height: 24px;
        cursor: pointer;
        box-sizing: border-box;
        display: block;
        border-radius: 4px;
        width: 50px;
        text-align: center;
        line-height: 24px;
      }
    }
  }

  .search {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    .search_flex{
      display: flex;
    }
    .search_flex {
      display: flex;
      align-items: center;
    }
  }

  .search_right {
    width: 560px;

    /deep/ .el-button {
      border: 1px solid #448aff;
      color: #fff;
      border-radius: 0;
      background-color: #448aff;
    }
  }

  .new_page {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    margin: 20px 0;
  }

  .dialog_btn {
    width: 100%;
    overflow: hidden;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
